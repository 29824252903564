import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IconDirective } from '@celum/shared/util';

import { NotificationsCenterService } from './notifications-center.service';

@Component({
  selector: 'systembar-notifications',
  templateUrl: './notifications-center.component.html',
  styleUrl: './notifications-center.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  //  Needed for the overlay to show up correctly
  encapsulation: ViewEncapsulation.None,
  imports: [OverlayModule, CommonModule, MatBadgeModule, MatIconModule, MatTooltipModule, TranslateModule, IconDirective]
})
export class NotificationsCenterComponent implements OnInit {
  @HostBinding('class.systembar-notifications') public hostCls = true;

  protected count$: Observable<string>;
  protected isPanelOpen = signal<boolean>(false);

  private notificationsCenterService = inject(NotificationsCenterService);

  public ngOnInit(): void {
    // TODO: Consider using a signal store when implementing WR2-15902
    this.count$ = this.notificationsCenterService.pollForNotificationCount();
  }
}
